import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

export interface SnackbarData {
	message: string;
	icon: 'success' | 'warning' | 'error' | 'info' | 'none';
	duration?: number;
	randomKey?: number;
}

@Component({
	standalone: true,
	imports: [NgClass, MatSnackBarModule, MatDialogModule, MatButtonModule, MatProgressBarModule],
	selector: 'yuno-notification-message-snackbar',
	templateUrl: './message-snackbar.component.html',
	styleUrls: ['./message-snackbar.component.scss']
	// TODO:
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageSnackbarComponent {
	count = 0;
	list: SnackbarData[] = [];

	constructor(
		@Inject(MAT_SNACK_BAR_DATA)
		public data: SnackbarData
	) {
		this.getProgress((data as SnackbarData).duration || 3);
	}

	isArray(obj: SnackbarData | SnackbarData[]) {
		return Array.isArray(obj);
	}

	getProgress(duration: number) {
		interval(10)
			.pipe(take(duration * 100))
			.subscribe(val => {
				this.count = val / duration;
			});
	}
}
