<!-- *ngIf="data && (isArray(data) === false)" -->
<div class="mb-2 block max-w-md font-medium text-yuno-gray-300">
	<mat-progress-bar
		mode="determinate"
		[value]="count"
		[color]="
			data.icon === 'success' ? 'success' : data.icon === 'error' ? 'warn' : 'primary'
		" />
	<div class="flex">
		@if (data.icon === 'success') {
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="mx-4 my-auto h-8 w-8"
				viewBox="0 0 20 20"
				fill="#4db36f">
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
					clip-rule="evenodd" />
			</svg>
		}
		@if (data.icon === 'error') {
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="mx-4 my-auto h-8 w-8"
				viewBox="0 0 20 20"
				fill="#c0392b">
				<path
					fill-rule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
					clip-rule="evenodd" />
			</svg>
		}
		@if (data.icon === 'info') {
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="mx-4 my-auto h-8 w-8"
				fill="none"
				viewBox="0 0 24 24"
				stroke="#3498db"
				stroke-width="2">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>
		}
		<div class="mb-2 mt-3 w-full" [ngClass]="{ 'ml-4': data.icon === 'none' }">
			<span class="snackbar-contents"> {{ data.message }} </span>
		</div>
	</div>
</div>
