import { YunoSharedUiButtonColors } from '@yuno/shared/ui';

export class DialogItem {
	title: string;
	message: string;
	buttons: {
		key: string;
		type?: YunoSharedUiButtonColors | undefined;
		confirm?: boolean;
	}[];
	confirm?: string;
}

export class SnackBarQueueItem {
	message: string;
	icon: 'error' | 'success' | 'info' | 'none';
	duration?: number;
}

export class ToastItem {
	message: string;
	position?:
		| 'bottom-center'
		| 'bottom-left'
		| 'bottom-right'
		| 'top-center'
		| 'top-left'
		| 'top-right';
	duration?: number;
	unique?: boolean;
	dismiss?: boolean;
	icon?: string;
}

export type ToastType = 'success' | 'error' | 'warning' | 'info' | 'loading';
