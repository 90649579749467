<h2 mat-dialog-title>{{ dialog.title }}</h2>
<mat-dialog-content class="mat-typography">
	<div [innerHTML]="dialog.message | safeHtml"></div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="gap-2">
	@for (btn of dialog.buttons; track btn) {
		<button
			yuno-shared-ui-button
			[mat-dialog-close]="btn?.confirm || false"
			[color]="btn?.type || primaryColor">
			{{ btn.key }}
		</button>
	}
</mat-dialog-actions>
